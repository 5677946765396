@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

/* 
    ------------
    styles-guide
    ------------
    $screen-xs: 576px;
    $screen-sm: 768px;
    $screen-md: 992px;
    $screen-lg: 1200px;
    $screen-xl: 1600px; 
*/

*, *::after, *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 100%;
}

body {
    font-family: 'Poppins', sans-serif;
}

.ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-link {
    color: #0172B1;
    text-decoration: underline;
    cursor: pointer;
    transition: color .3s;
}

.text-link:hover {
    color: #29aef5;
}

.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.d-flex { display: flex; }

.d-flex-column { flex-direction: column; }

.center {
    justify-content: center;
    align-items: center;
    text-align: center;
}