:root {
    --success-color: #1BC55F;
    --success-subtle-color: #D5F7C9;

    --danger-color: #FF0000;
    --danger-subtle-color: #FFE2E5;

    --info-color: #4AA3FF;
    --info-subtle-color: #E1F0FF;

    --warning-color: #FFA800;
    --warning-subtle-color: #FFF4DE;
}

/* card base style */
.card-container {
    display: flex;
    height: 76px;
    /* min-width: 290px; */
    border-radius: 7px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
          
.card-container > div { padding: 10px; }

.card-container .icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-container .icon > svg { font-size: 1.8em; } 

.card-container .content {
    /* flex: 1; */
    
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card-container .content > .title { 
    color: #424241; 
    font-size: 18px;
    font-weight: bold;
}

.card-container .content > .body { 
    color: #7C7B7B; 
    font-size: 17px;
    letter-spacing: 0.5px;
}

.card-container .statistic {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.card-container .statistic > div {
    text-align: center;
    border-radius: 7px;
    padding: 7px 5px;
    width: 48px;
}

/* success style */
.card-success { border-left: 7px solid var(--success-color); }
.card-success .icon > svg { color: var(--success-color); }
.card-success .statistic > div { background-color: var(--success-subtle-color); }
.card-success .statistic div > h2 { color: var(--success-color); }

/* danger style */
.card-danger { border-left: 7px solid var(--danger-color); }
.card-danger .icon > svg { color: var(--danger-color); }
.card-danger .statistic > div { background-color: var(--danger-subtle-color); }
.card-danger .statistic div > h2 { color: var(--danger-color); }

/* info style */
.card-info { border-left: 7px solid var(--info-color); }
.card-info .icon > svg { color: var(--info-color); }
.card-info .statistic > div { background-color: var(--info-subtle-color); }
.card-info .statistic div > h2 { color: var(--info-color); }

/* warning style */
.card-warning { border-left: 7px solid var(--warning-color); }
.card-warning .icon > svg { color: var(--warning-color); }
.card-warning .statistic > div { background-color: var(--warning-subtle-color); }
.card-warning .statistic div > h2 { color: var(--warning-color); }