.rich-text-container{
  margin-top: 10px;
  .rich-text-toolbar{
    border-radius: 5px;
  }
  .rich-text-editor{
    border: 1px solid lightgray;
    border-radius: 5px;
    min-height: 150px;
    padding: 0 10px;
  }
}